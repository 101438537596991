import {
  ExternRevLink,
  FooterRevLinkExternal,
  FooterRevLinkInternal,
  RevPageLink,
} from "../components/common/Buttons";
import * as links from "../externalLinks";
import * as pages from "../navigation";
import {
  faCircleQuestion,
  faFileCode,
  faEnvelope,
  faSquarePhone,
  faLink,
  faShoppingBag,
  faBook,
  faSquareFull,
  faFilm,
} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="footer has-background-white-ter is-flex-align-items-flex-end mt-auto">
      <div className="container">
        <div className="columns">
          <div className="column">
            {/* eslint-disable-next-line  */}
            <a className="images pb-4" href={pages.home}>
              <img
                src="images/rev-logo.png"
                alt="myREV logo"
                width="100px"
                height="fit-content"
              />
            </a>
            <div className="content pt-5">
              <p>
                <strong>© {year} Vastgoed Adviseurs Online</strong>
              </p>
              <p>Alle rechten voorbehouden</p>
              <p>
                <a href={pages.disclaimer}>Disclaimer</a>&nbsp;|&nbsp;
                <a href={pages.cookies}>Cookies</a>
              </p>
            </div>
          </div>
          <div className="column">
            <div className="content">
              <h3 className="subtitle is-5 has-text-weight-bold is-size-6-mobile">
                Contact
              </h3>
              <p>
                {" "}
                Heeft u vragen? Neem tijdens kantooruren contact met ons op of
                bekijk onze instructievideo's.
              </p>

              <FooterRevLinkExternal
                icon={faEnvelope}
                text={"info@evao.nl"}
                path={"mailto:info@evao.nl"}
              />
              <FooterRevLinkExternal
                icon={faSquarePhone}
                text={"040-2800024"}
                path={"tel:0402800024"}
              />
              <FooterRevLinkExternal
                icon={faFilm}
                path={links.youtubePlaylist}
                target={"_blank"}
                rel={"noopener"}
                text={"Instructievideo's"}
              />
            </div>
          </div>
          <div className="column">
            <div className="content">
              <h3 className="subtitle is-5 has-text-weight-bold is-size-6-mobile">
                Meer over REV<sup>®</sup>
              </h3>

              <FooterRevLinkExternal
                icon={faSquareFull}
                text={
                  <>
                    Over REV<sup>®</sup>
                  </>
                }
                path={"/over-rev"}
              />
              <FooterRevLinkInternal
                icon={faCircleQuestion}
                text={"Support & FAQ"}
                path={pages.support}
              />
              <FooterRevLinkExternal
                icon={faShoppingBag}
                text={"VAOshop"}
                path={links.shop}
                target={"_blank"}
                rel={"noopener"}
              />
              <FooterRevLinkInternal
                icon={faLink}
                text={"Handige Links"}
                path={pages.handigeLinks}
              />
              <FooterRevLinkInternal
                icon={faBook}
                text={"WikiVastgoed"}
                path={pages.wikivastgoed}
              />
              <FooterRevLinkInternal
                icon={faFileCode}
                text={"Webservices"}
                path={pages.webservices}
              />
            </div>
          </div>
          <div className="column">
            <div className="content is-normal">
              <h3 className="subtitle is-5 has-text-weight-bold is-size-6-mobile">
                Schrijf u in voor de nieuwsbrief
              </h3>
              <p>Altijd op de hoogte van de laatste ontwikkelingen?</p>
              <div className="buttons">
                <ExternRevLink
                  className={"button rev-btn-inschrijf"}
                  path={links.signUp}
                  target={"_blank"}
                  rel={"noopener"}
                  text={"Ja, schrijf mij in!"}
                />
                <RevPageLink
                  className={"button is-primary is-outlined"}
                  text={"Nieuwsarchief"}
                  path={pages.nieuws}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
